<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">CDSID</div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  v-model="query.cdsid"
                  placeholder="请输入"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.utableTite1") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  v-model="query.fullName"
                  placeholder="请输入"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.utableTite3") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="query.userType"
                  clearable
                  placeholder="请选择"
                >
                  <el-option label="JLRC" value="JLRC"></el-option>
                  <el-option label="CJLR" value="CJLR"></el-option>
                </el-select>
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.utableTite6") }}
              </div>

              <div>
                <el-date-picker
                  v-model="times"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="onclear">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="getList(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="sync()">
                  {{ $t("edpBackendEntry.utableSync") }}</el-button
                >

                <el-button type="primary" size="small" @click="syncDepart">{{
                  $t("edpBackendRole.userDepartSync")
                }}</el-button>
              </div>
            </div>
            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column fixed prop="cdsid" label="CDSID" width="200">
              </el-table-column>

              <el-table-column
                prop="fullName"
                :label="$t('edpBackendEntry.utableTite1')"
              >
              </el-table-column>

              <el-table-column
                prop="email"
                :label="$t('edpBackendEntry.utableTite2')"
              ></el-table-column>

              <el-table-column
                prop="userType"
                :label="$t('edpBackendEntry.utableTite3')"
              ></el-table-column>

              <el-table-column
                prop="roleNames"
                :label="$t('edpBackendEntry.utableTite4')"
              ></el-table-column>

              <el-table-column
                prop="loginTime"
                :label="$t('edpBackendEntry.utableTite5')"
              ></el-table-column>

              <el-table-column
                :label="$t('edpBackendCommon.operate')"
                fixed="right"
                min-width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="onEdit(scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >

                  <el-button type="text" @click="onPermit(scope.row)">
                    {{ $t("edpBackendRole.userPermit") }}</el-button
                  >

                  <el-button
                    v-if="scope.row.isAdExist == 0"
                    type="text"
                    @click="deleteRow(scope.row)"
                  >
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="query.current"
              :page-size="query.size"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <el-dialog
      :title="dialogTitle"
      width="50%"
      :visible.sync="editShow"
      :before-close="onClose"
    >
      <el-form :model="form" ref="userForm" label-width="120px" :inline="true">
        <el-form-item label="CDSID">
          <el-input v-model="form.cdsid" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.utableTite1')">
          <el-input
            v-model="form.fullName"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.utableTite3')">
          <el-input
            v-model="form.userType"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.utableTite2')">
          <el-input v-model="form.email" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.utableTite7')"
          prop="roleIds"
          required
        >
          <el-select
            v-model="form.roleIds"
            :placeholder="$t('edpBackendCommon.placeholder')"
            multiple
            clearable
          >
            <el-option
              v-for="(item, i) in roles"
              :key="i"
              :label="item.roleName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose(() => (editShow = false))">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="save()">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="dialogTitle_permit"
      width="900px"
      :visible.sync="permitDialog_show"
    >
      <div class="edp-backend-userPermitDialog">
        <section class="edp-backend-userPermitDialog-section">
          <div class="edp-backend-userPermitDialog-subTitle">
            {{ $t("edpBackendRole.permitDialogSubTitle1") }}
          </div>

          <div class="edp-backend-userPermitDialog-subContent">
            <div class="edp-backend-userPermitDialog-subContent-search">
              <el-input
                max="100"
                v-model="dataUserTreeSearch"
                :placeholder="$t('edpBackendRole.userTreeSearchPlaceholder')"
                clearable
              />
            </div>

            <el-tree
              :props="defaultProps"
              :data="dataUserTree"
              show-checkbox
              node-key="finalCode"
              ref="treeUserPermit"
              :default-checked-keys="dataUserTreeDefault"
              :filter-node-method="filterNode"
              @check-change="dataUserTreeChange"
              v-if="dataUserTreeShow"
            >
            </el-tree>
          </div>
        </section>

        <section class="edp-backend-userPermitDialog-section">
          <div class="edp-backend-userPermitDialog-subTitle">
            {{ $t("edpBackendRole.permitDialogSubTitle2") }}
          </div>

          <div class="edp-backend-userPermitDialog-subContent">
            <div
              class="edp-backend-userPermitDialog-alSeledList"
              v-for="(item, index) in userPermitTreeResult"
              :key="'userPermitTreeResult' + index"
            >
              <p>{{ item.finalName }}</p>

              <em @click="permitDialog_delete(item, index)">{{
                $t("edpBackendCommon.delete")
              }}</em>
            </div>
          </div>
        </section>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="permitDialog_show = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="permitDialog_save()">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "backendUser",
  data() {
    return {
      pageList: [{}],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: false,
      editShow: false,
      dialogTitle: this.$t("edpBackendCommon.edit"),
      form: {
        id: 0,
        cdsid: "",
        fullName: "",
        email: "",
        userType: "",
        modifyTime: "",
        roleNames: "",
        roleIds: null,
      },
      times: ["", ""],
      query: {
        current: 1,
        size: 10,
        cdsid: "",
        fullName: "",
        userType: "",
        modifyTimeStart: "",
        modifyTimeEnd: "",
      },
      roles: [],

      dialogTitle_permit: this.$t("edpBackendRole.permitDialogTitle"),
      permitDialog_show: false,
      defaultProps: {
        children: "children",
        label: "finalName",
      },

      dataUserTree: [],
      userPermitTreeResult: [],
      dataUserTreeShow: false,
      dataUserTreeDefault: [],
      dataUserTree_selectId: "",
      dataUserTreeSearch: "",
    };
  },
  watch: {
    times(val) {
      if (val) {
        this.query.modifyTimeStart = val[0];
        this.query.modifyTimeEnd = val[1];
      } else {
        this.query.modifyTimeStart = "";
        this.query.modifyTimeEnd = "";
      }
    },
    dataUserTreeSearch(val) {
      this.$refs.treeUserPermit.filter(val);
    },
  },
  mounted() {
    this.getList();
    this.getRoleList();
  },
  methods: {
    ...mapActions({
      userList: "user/userList",
      userEdit: "user/userEdit",
      roleList: "backendRole/roleList",
      userSync: "user/userSync",
      userDel: "user/userDel",
      userPermitListApi: "backendRole/userPermitListApi",
      userPermitUpdateApi: "backendRole/userPermitUpdateApi",
      userSyncDepartData: "backendRole/userSyncDepartData",
    }),
    pageTreeResultInit() {
      this.userPermitTreeResult = this.$refs.treeUserPermit.getCheckedNodes(
        true,
        true
      );
    },
    pageTreeInit(data) {
      data.forEach((val) => {
        this.$set(
          val,
          "finalName",
          val.finalName
            ? val.finalName
            : this.$i18n.locale === "en"
            ? val.englishName || val.name
            : val.name || val.englishName
        );
        this.$set(val, "finalCode", val.finalCode ? val.finalCode : val.code);

        let userLsAry = [];

        if (val.userList && val.userList.length > 0) {
          val.userList.forEach((val) => {
            this.$set(val, "finalName", val.fullName);
            this.$set(val, "finalCode", val.cdsid);

            if (val.selected) {
              this.userPermitTreeResult.push(val);
              this.dataUserTreeDefault.push(val.cdsid);
            }
          });

          userLsAry = JSON.parse(JSON.stringify(val.userList));
        }

        if (val.children && val.children.length > 0) {
          let childrenLsAry = JSON.parse(JSON.stringify(val.children));

          val.children = childrenLsAry.concat(userLsAry);

          this.pageTreeInit(val.children);
        } else {
          this.$set(val, "children", userLsAry);
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return (
        (data.finalName &&
          data.finalName.toLowerCase().indexOf(value.toLowerCase()) != -1) ||
        (data.cdsid && data.cdsid.indexOf(value) != -1)
      );
    },
    async permitDialogInit(id) {
      this.userPermitTreeResult = [];
      this.dataUserTreeDefault = [];
      this.dataUserTree_selectId = id;

      let res = await this.userPermitListApi(id);

      this.dataUserTree = res.data && res.data.data ? res.data.data : [];

      this.pageTreeInit(this.dataUserTree);

      this.dataUserTreeShow = true;

      this.$forceUpdate();
    },
    async getList(val) {
      if (val) {
        this.query.current = val;
      }
      let params = { ...this.query };
      let res = await this.userList(params);

      this.pageList = res.data.records;
      this.query.current = res.data.current;
      this.query.size = res.data.size;
      this.pageTotal = res.data.total;
    },
    async getRoleList() {
      let res = await this.roleList({ current: 1, size: 100 });

      this.roles = res.data.data.records;
    },
    handleCurrentChange(val) {
      this.query.current = val;
      this.getList();
    },
    onEdit(data) {
      this.form = JSON.parse(JSON.stringify(data));

      if (this.form.roleIds !== null && this.form.roleIds.length) {
        this.form.roleIds = this.form.roleIds
          .split(",")
          .map((e) => parseInt(e));
      } else {
        this.form.roleIds = [];
      }
      this.editShow = true;
      const that = this;
      this.$nextTick(() => {
        that.$refs.userForm.clearValidate();
      });
    },
    onclear() {
      this.query = {
        current: 1,
        size: 10,
        cdsid: "",
        fullName: "",
        userType: "",
        modifyTimeStart: "",
        modifyTimeEnd: "",
      };
      this.times = ["", ""];
    },
    onClose(done) {
      for (var key in this.form) {
        this.form[key] = key === "roleIds" ? null : "";
      }
      done();
    },
    async save() {
      let data = { id: this.form.id, roleIds: this.form.roleIds };
      let res = await this.userEdit(data);

      if (res.success) {
        this.editShow = false;
        for (var key in this.form) {
          this.form[key] = key === "roleIds" ? null : "";
        }
        this.getList();
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });
      }
    },
    async sync() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await this.userSync();

      if (res.success) {
        loading.close();
        this.getList(1);
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });
      } else {
        loading.close();
      }
    },
    async deleteRow(val) {
      this.$confirm("用户删除后，用户将无法登录，请确认是否删除？", "删除")
        .then(async (_) => {
          let res = await this.userDel(val.id);
          if (res.success) {
            this.getList(1);
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        })
        .catch((_) => {});
    },
    async onPermit(data) {
      this.permitDialogInit(data.id);

      this.permitDialog_show = true;
    },
    async permitDialog_save() {
      let seRsLs = [];

      this.userPermitTreeResult.forEach((val) => {
        if (val.cdsid) {
          seRsLs.push(val.cdsid);
        }
      });

      let params = {
        id: this.dataUserTree_selectId,
        params: {
          id: this.dataUserTree_selectId,
          userCdsids: seRsLs,
        },
      };

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let res = await this.userPermitUpdateApi(params);

      loading.close();

      if (res.data.success) {
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });

        this.permitDialog_show = false;
      }
    },
    dataUserTreeChange() {
      this.pageTreeResultInit();
    },
    permitDialog_delete(data, index) {
      this.userPermitTreeResult.splice(index, 1);

      this.dataUserTreeDefault.some((val, e) => {
        if (val == data.cdsid) {
          this.dataUserTreeDefault.splice(e, 1);
        }
      });

      this.$refs.treeUserPermit.setChecked(data.cdsid, false, true);
    },
    async syncDepart() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let res = await this.userSyncDepartData();

      loading.close();

      if (res.data.success) {
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
